<template>
  <div>
    <b-modal
      id="suficienciaModal"
      title="Suficiencia presupuestal"
      v-model="show"
      centered
      no-close-on-esc
      no-close-on-backdrop
      hide-footer
      size="xl"
      dialog-class="x-modal-lg"
    >
      <template #modal-title>
        <!-- Tags -->
        <div class="my-2">
          <b-badge variant="success" v-if="mes">Mes: {{ getMonthById(mes).value }}</b-badge>
        </div>
      </template>

      <!-- Body -->
      <template>
        <div class="card card-header-actions">
          <div class="card-header">
            Listado de suficiencia presupuestal actuales
            <!-- Nuevo registro. Abre sidebar. -->
            <b-button v-b-toggle.suficienciaFormSidebar size="sm" variant="success">Crear</b-button>
          </div>
          <div class="p-3">
            <suficiencia-list :mes="mes" />
          </div>
        </div>

        <suficiencia-sidebar :mes="mes"></suficiencia-sidebar>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SuficienciaList from '@/components/SuficienciaPresupuestal/SuficienciaList'
import SuficienciaSidebar from '@/components/SuficienciaPresupuestal/SuficienciaSidebar'

export default {
  name: 'SuficienciaModal',

  components: {
    SuficienciaList,
    SuficienciaSidebar
  },

  props: {
    value: {
      type: Boolean,
      default: false,
      required: true
    },

    mes: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      show: this.value,

      showImportForm: false
    }
  },

  computed: mapGetters('UIModule', ['getMonthById']),

  watch: {
    value (newValue) {
      this.show = newValue
    },

    show (newValue) {
      this.$emit('input', newValue)
    }
  },

  methods: {
    onShowImportForm (value) {
      this.showImportForm = value
    }
  }
}
</script>
